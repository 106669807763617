.Container {
  margin: 40px;
}

.TextPrimary {
  color: rgb(127, 127, 255);
  font-size: 2vw;
  text-align: center;
}

.TextHeader {
  color: rgb(0, 136, 255);
  font-size: 2.5vw;
}

.VaCategoryAudioContainer {
  margin-left: 3vw;
  border-left: 3px dotted rgb(0, 136, 255);;
  display: flex;
  flex-direction: column;
}

.LinkPrimary {
  color: rgb(0, 39, 73);
  font-size: 1.4vw;
  font-weight: bold;
  margin: auto;
  text-decoration: none;
  margin-right: 5px;
}

.HeaderBar {
  background-color: rgb(127, 127, 255);
  width: 60vw;
  height: 5vh;
  margin: auto;
  display: flex;
  border-radius: 6px;
  align-items: center;
  justify-content: space-evenly;
  padding: 0px 5px 0px 5px;
}

.HeaderBarOption {
  display: flex;
  border-radius: 6px;
  padding: 5px;
  align-items: center;
  text-decoration: none;
}

a.HeaderBarOption:hover {
  background-color: aqua;
}

a.HeaderBarOption:active {
  background-color:#282c34;
}

.AudioFile {
  margin-left: 2vw;
  margin-bottom: 1vw;
}

.ProfileImage {
  text-align: left;
  margin-right: 2vw;
  width: 12vw;
  height: auto;
}

.Icon {
  margin-right: 5px;
  margin-left: 5px;
  width: 2vw;
  height: auto;
}

.Intro {
  display: flex;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
